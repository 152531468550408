import React, { PureComponent } from 'react'
import { withPrefix } from 'gatsby-link'
import { fallback } from '../i18n/locales'

class Redirect extends PureComponent {
  constructor(props) {
    super(props)

    const { pathname } = props.location

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const newUrl = withPrefix(`/${fallback}${pathname}`)
      const withHash = props.location.hash || ''

      window.location.replace(newUrl + withHash)
    }
  }

  render() {
    return <div />
  }
}

export default Redirect
