const localeData = [
  ...require('react-intl/locale-data/fr'),
  ...require('react-intl/locale-data/en'),
  ...require('react-intl/locale-data/nl'),
]

const languages = [
  { value: 'fr', text: 'FR' },
  { value: 'en', text: 'EN' },
  { value: 'nl', text: 'NL' },
]

const fallback = 'en'

module.exports = {
  localeData,
  languages,
  fallback,
}
